@font-face {
  font-family: 'NotoSansLight';
  font-style: normal;
  font-weight: 400;
  src: url('../public/assets/fonts/notosans-light/notosans-light-webfont.ttf'),
    url('../public/assets/fonts/notosans-light/notosans-light-webfont.woff'),
    url('../public/assets/fonts/notosans-light/notosans-light-webfont.woff2');
}

@font-face {
  font-family: 'NotoSansRegular';
  font-style: normal;
  font-weight: 400;
  src: url('../public/assets/fonts/notosans-regular/notosans-regular-webfont.ttf'),
    url('../public/assets/fonts/notosans-regular/notosans-regular-webfont.woff'),
    url('../public/assets/fonts/notosans-regular/notosans-regular-webfont.woff2');
}

@font-face {
  font-family: 'NotoSansMedium';
  font-style: normal;
  font-weight: 400;
  src: url('../public/assets/fonts/notosans-medium/notosans-medium-webfont.ttf'),
    url('../public/assets/fonts/notosans-medium/notosans-medium-webfont.woff'),
    url('../public/assets/fonts/notosans-medium/notosans-medium-webfont.woff2');
}

@font-face {
  font-family: 'NotoSansSemiBold';
  font-style: normal;
  font-weight: 700;
  src: url('../public/assets/fonts/notosans-semibold/notosans-semibold-webfont.ttf'),
    url('../public/assets/fonts/notosans-semibold/notosans-semibold-webfont.woff'),
    url('../public/assets/fonts/notosans-semibold/notosans-semibold-webfont.woff2');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../public/assets/fonts/materialicons-regular/MaterialIcons-Regular.ttf');
}

body {
  margin: 0;
  width: 100vw;
  max-width: 100%;
  font-family: NotoSansRegular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

input {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: NotoSansRegular, sans-serif;
}

input::placeholder {
  font-size: 14px !important;
}

.custom-marker-cluster {
  background: #2e72ad;
  border: 3px solid #2e72ad;
  border-radius: 50%;
  color: #ffffff;
  height: 56px;
  line-height: 56px;
  text-align: center;
  width: 56px;
  font-family: NotoSansRegular, sans-serif;
  font-size: 20px;
  font-weight: 600;
  box-shadow: 0 0 5px 5px #a4c1db;
}

.custom-marker-cluster:hover {
  background: #ffffff;
  color: #2e72ad;
}

.custom-marker-cluster-legal {
  background: #127858;
  border: 3px solid #127858;
  box-shadow: 0 0 5px 5px #b3dfd2;
}

.custom-marker-cluster-legal:hover {
  color: #127858;
}

.custom-marker-cluster-illegal {
  background: #c41c2b;
  border: 3px solid #c41c2b;
  box-shadow: 0 0 5px 5px #e69396;
}

.custom-marker-cluster-illegal:hover {
  color: #c41c2b;
}

.MuiMultiSectionDigitalClock-root {
  scrollbar-width: thin;
  & > ul::-webkit-scrollbar {
    width: 6px;
    background: #dfdfe2;
  }
  & > ul::-webkit-scrollbar-thumb {
    background: #aaaaaf;
    border-radius: 5px;
  }
}

.grecaptcha-badge {
  visibility: hidden;
  right: 15px !important;
}

.material-symbols-outlined {
  font-family: 'Material Icons';
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

code {
  background: #eee;
  padding: 3px;
  white-space: normal;
}

blockquote {
  padding: 8px 20px;
  border-left: 3px solid #eee;
  margin: 15px 0px;
  color: #98a2b3;
}
blockquote > p {
  margin: 0;
}
